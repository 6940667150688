jQuery(document).ready(function () {
  jQuery(".accordion-content").on("show.bs.collapse", function () {
    // var id = jQuery(this).attr("data-service-id");

    // Hide all asides
    //jQuery(".card-aside").removeClass('inplace');

    // Show this aside
    //jQuery("#card-aside-" + id).addClass('inplace');

    var element_id = jQuery(this).attr("id");
    console.log(element_id);
    // This accordion
    var this_accordion_id = jQuery(this).attr("data-parent"); // has hash

    // Fidn the parent card and add active to it.
    jQuery(this_accordion_id + " .card").removeClass("active");
    jQuery(this_accordion_id + " .card").removeClass("inplace");
    jQuery("#" + element_id)
      .parent()
      .parent()
      .addClass("active");

    // Add inplace after .2 seconds
    setTimeout(function () {
      jQuery("#" + element_id)
        .parent()
        .parent()
        .addClass("inplace");
    }, 220);
  });

  jQuery(".section-accordion.horizontal .block-accordion .card").on(
    "click",
    function (e) {
      // Toggle collapse
      jQuery(this).find(".accordion-content").collapse("show");
    }
  );
});

jQuery(document).ready(function () {
  var controller = new ScrollMagic.Controller();



  // Animate the timeline
  var animateGrid = Array.prototype.slice.call(
    document.querySelectorAll(".timeline-anim ul")
  );

  animateGrid.forEach(function (self) {
    var entry = self;
    var timelineScene = new TimelineMax();
    timelineScene.fromTo(
      entry,
      0.5,
      { opacity: 0, x: -20, width: 20 },
      {
        opacity: 1,
        x: 0,
        stagger: 0.2,
        width: '100%'
      }
    );
    var tl_scene = new ScrollMagic.Scene({
      triggerElement: self,
      triggerHook: "onEnter",
      duration: 0,
      offset: 150,
    })
      .setTween(timelineScene)
      .setClassToggle(self, "active")
      .addTo(controller);
  });











  // Sticky cards unfolding
/*
  var $trigger3 = $('<span class="trigger3" style="visibility:hidden;"></span>');
  $myElement.after( $trigger3 );

  //var introScene = new ScrollMagic.Scene({triggerElement: '#img_above_blue_bar', triggerHook: 0, duration: 200}).setTween(introTl).addTo(controller);
  var card_scroller_height = jQuery('.card-scroller').height();
  // build scene
  var scene = new ScrollMagic.Scene({triggerElement: ".card-scroller", duration: card_scroller_height, offset: 600})
  .setPin(".card-scroller")
  .addTo(controller);
  */


});
